import React from 'react';

import { SamplesTranslationSelection } from '../SamplesTranslationSelection/SamplesTranslationSelection';
import { SampleDetailsDialogSelection } from '../../SampleDetailsDialogSelection/SampleDetailsDialogSelection';

import {
  SampleDetailsDialogSelectionsComparisonButton,
  SampleDetailsDialogSelectionsContainer,
  SampleDetailsDialogSelectionsInnerContainer
} from '../../SampleDetailsDialogBody.styles';
import { InteractionSchema, StepSchema } from '@/helpers/services/api';

import { constants } from '@/components/Samples/SamplesTable/samplesTable.constants';

interface SampleDetailsDialogInteractionSelectionsProps {
  sample: InteractionSchema;
  section: string;
  isTranslation: boolean;
  isCompareDisabled: boolean;
  selectionsInnerCount: number;
  steps: StepSchema[] | undefined;
  selectedInteraction: InteractionSchema;
  setSection: (section: string) => void;
  setIsTranslation: (isTranslation: boolean) => void;
  setIsComparisonView: (isComparisonView: boolean) => void;
  setSelectedInteraction: (interaction: InteractionSchema) => void;
  checkSectionData: (val: string) => { created?: string; data?: { input?: string; output?: string } };
}

const {
  sampleKey,
  historyKey,
  informationRetrievalKey,
  fullPromptKey,
  expectedOutputKey,
  comparisonBtnLabel,
  stepNameKey
} = constants;

export const SampleDetailsDialogInteractionSelections = (props: SampleDetailsDialogInteractionSelectionsProps) => {
  const {
    steps,
    sample,
    section,
    isTranslation,
    isCompareDisabled,
    selectedInteraction,
    selectionsInnerCount,
    setSection,
    checkSectionData,
    setIsTranslation,
    setIsComparisonView,
    setSelectedInteraction
  } = props;

  // If the selected interaction is not the current interaction (sample), then show the sample selection
  if (selectedInteraction?.user_interaction_id !== sample?.user_interaction_id) {
    return (
      <SampleDetailsDialogSelectionsContainer onClick={() => setSelectedInteraction(sample)}>
        <SampleDetailsDialogSelection
          section={''}
          key={sampleKey}
          value={sampleKey}
          onlySampleData={true}
          interactionType={sample?.interaction_type?.name}
          setSection={setSection}
          checkSectionData={checkSectionData}
        />
      </SampleDetailsDialogSelectionsContainer>
    );
  } else {
    return (
      <SampleDetailsDialogSelectionsContainer>
        <SampleDetailsDialogSelection
          key={sampleKey}
          value={sampleKey}
          section={section}
          interactionType={sample?.interaction_type?.name}
          setSection={setSection}
          checkSectionData={checkSectionData}
        />
        <SampleDetailsDialogSelectionsInnerContainer count={selectionsInnerCount}>
          {[informationRetrievalKey, historyKey, expectedOutputKey]?.map(val => (
            <SampleDetailsDialogSelection
              key={val}
              value={val}
              needBorder={true}
              section={section}
              setSection={setSection}
              checkSectionData={checkSectionData}
            />
          ))}
          {steps?.[0] &&
            steps?.map(step => (
              <SampleDetailsDialogSelection
                step={step}
                needBorder={true}
                section={section}
                key={stepNameKey(step?.id, step?.name)}
                value={stepNameKey(step?.id, step?.name)}
                setSection={setSection}
                checkSectionData={checkSectionData}
              />
            ))}
          <SampleDetailsDialogSelection
            needBorder={true}
            section={section}
            key={fullPromptKey}
            value={fullPromptKey}
            setSection={setSection}
            checkSectionData={checkSectionData}
          />
        </SampleDetailsDialogSelectionsInnerContainer>
        <SamplesTranslationSelection
          isTranslation={isTranslation}
          translation={sample?.translated}
          setIsTranslation={setIsTranslation}
        />
        {!isCompareDisabled && (
          <SampleDetailsDialogSelectionsComparisonButton
            variant="outlined"
            label={comparisonBtnLabel}
            data-testid="CompareToOtherVersion"
            onClick={() => setIsComparisonView(true)}
          />
        )}
      </SampleDetailsDialogSelectionsContainer>
    );
  }
};
