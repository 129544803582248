import React, { useEffect, useState, useMemo } from 'react';

import { StackProps } from '@mui/material';

import { SamplesDetailsBodyItem } from './SamplesDetailsBodyItem/SamplesDetailsBodyItem';
import { SamplesDetailsBodyAllPropertiesType } from './SamplesDetailsBodyItem/SampleDetailsBodyProperties/SampleDetailsBodyProperties';
import { SampleDetailsDialogInteractionSelections } from './SamplesDetailsBodyItem/SampleDetailsDialogInteractionSelections/SampleDetailsDialogInteractionSelections';

import { StyledLoader } from '@/components/lib';
import {
  SampleDetailsDialogBodyContainer,
  SampleDetailsDialogSessionsSelectionContainer
} from './SampleDetailsDialogBody.styles';

import {
  StepSchema,
  TextEntrySchema,
  AnnotationSchema,
  InteractionSchema,
  PropertyDefinitionSchema,
  listInteractionsByFilter,
  ReadPropertyConfigSchema,
  getSimilarApiV1ApplicationVersionsComparisonPut,
  useGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet
} from '@/helpers/services/api';
import { getAppStorage } from '@/helpers/utils/localStorage';
import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';

import { SelectedColumnType } from '@/components/Samples/Samples.types';
import { constants } from '../../../Samples/SamplesTable/samplesTable.constants';

interface SampleDetailsDialogBodyProps extends StackProps {
  appId?: number;
  isOutput?: boolean;
  isPentest?: boolean;
  sample: InteractionSchema;
  isAnnotationInteraction?: boolean;
  selectedColumns?: SelectedColumnType[];
  listOfProperties?: ReadPropertyConfigSchema[];
  setIsComparisonView: (isComparisonView: boolean) => void;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
  updateAnnotation?: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
}

const { sampleKey, historyKey, informationRetrievalKey, fullPromptKey, expectedOutputKey, na, stepNameKey } = constants;

export const SampleDetailsDialogBody = (props: SampleDetailsDialogBodyProps) => {
  const {
    appId,
    sample,
    isPentest,
    selectedColumns,
    listOfProperties,
    isAnnotationInteraction,
    updateProperty,
    getPropertyInfo,
    updateAnnotation,
    setIsComparisonView
  } = props;

  const [section, setSection] = useState(sampleKey);
  const [isTranslation, setIsTranslation] = useState(false);
  const [isCompareDisabled, setIsCompareDisabled] = useState(true);
  const [isSessionInteractionsLoading, setIsSessionInteractionsLoading] = useState(false);
  const [sessionInteractions, setSessionInteractions] = useState<InteractionSchema[]>([]);
  const [selectedInteraction, setSelectedInteraction] = useState<InteractionSchema>(sample);

  const { versionId, type } = getAppStorage();

  const { data: steps } =
    useGetStepsForInteractionApiV1ApplicationVersionsAppVersionIdInteractionsUserInteractionIdStepsGet(
      versionId,
      selectedInteraction?.user_interaction_id
    );

  const stepData = Array.isArray(steps) && steps?.filter(s => stepNameKey(s?.id, s?.name) === section)[0];
  const properties = {
    input: selectedInteraction?.input_properties,
    output: selectedInteraction?.output_properties,
    custom: selectedInteraction?.custom_properties,
    llm: selectedInteraction?.llm_properties,
    ...(isPentest && { garak: selectedInteraction?.garak_properties })
  } as SamplesDetailsBodyAllPropertiesType;

  const updateAnnotationAndSessionInteraction = (
    interactionId: string,
    annotation: string,
    reason: string,
    isEstimated?: boolean
  ) => {
    if (updateAnnotation) {
      updateAnnotation(interactionId, annotation, reason, isEstimated);

      // Update the annotation of the selected interaction and the sessions list so it will be visible in the UI immediately
      const newSessionsInteractions = sessionInteractions.map(sessionInteraction => {
        if (sessionInteraction.user_interaction_id === interactionId) {
          // Update the annotation of the selected interaction
          setSelectedInteraction({
            ...sessionInteraction,
            annotation: {
              ...sessionInteraction.annotation,
              reason,
              annotation,
              is_estimated: !!isEstimated
            } as AnnotationSchema
          });

          return {
            ...sessionInteraction,
            annotation: {
              ...sessionInteraction.annotation,
              reason,
              annotation,
              is_estimated: !!isEstimated
            } as AnnotationSchema
          };
        }

        return sessionInteraction;
      });

      // Update the sessions list
      setSessionInteractions(newSessionsInteractions);
    }
  };

  const checkSectionData = (
    value: string
  ): {
    created?: string;
    data?: {
      input?: string;
      output?: string;
    };
  } => {
    switch (value) {
      case sampleKey:
        return {
          created: selectedInteraction?.input?.created_at,
          data: {
            input: isTranslation
              ? selectedInteraction?.translated?.input?.data || na
              : selectedInteraction?.input?.data,
            output: isTranslation
              ? selectedInteraction?.translated?.output?.data || na
              : selectedInteraction?.output?.data
          }
        };
      case informationRetrievalKey: {
        const latestInformationRetrieval = Array?.isArray(selectedInteraction?.information_retrieval)
          ? selectedInteraction?.information_retrieval?.reduce((latest, current) => {
              return new Date(latest?.created_at || 0) > new Date(current.created_at) ? latest : current;
            }, {} as TextEntrySchema)
          : null;

        return {
          created: latestInformationRetrieval?.created_at,
          data: {
            output: isTranslation
              ? Array?.isArray(selectedInteraction?.translated?.information_retrieval)
                ? selectedInteraction?.translated?.information_retrieval?.map(item => item?.data)?.join('\n')
                : ''
              : Array?.isArray(selectedInteraction?.information_retrieval)
                ? selectedInteraction?.information_retrieval?.map(item => item?.data)?.join('\n')
                : ''
          }
        };
      }
      case historyKey: {
        const latestHistory = Array?.isArray(selectedInteraction?.history)
          ? selectedInteraction?.history?.reduce((latest, current) => {
              return new Date(latest?.created_at || 0) > new Date(current.created_at) ? latest : current;
            }, {} as TextEntrySchema)
          : null;

        return {
          created: latestHistory?.created_at,
          data: {
            output: isTranslation
              ? Array?.isArray(selectedInteraction?.translated?.history)
                ? selectedInteraction?.translated?.history?.map(item => item?.data)?.join('\n')
                : ''
              : Array?.isArray(selectedInteraction?.history)
                ? selectedInteraction?.history?.map(item => item?.data)?.join('\n')
                : ''
          }
        };
      }
      case fullPromptKey:
        return {
          created: selectedInteraction?.prompt?.created_at,
          data: {
            output: isTranslation ? selectedInteraction?.translated?.prompt?.data : selectedInteraction?.prompt?.data
          }
        };

      case expectedOutputKey:
        return {
          created: selectedInteraction?.expected_output?.created_at,
          data: {
            output: isTranslation
              ? selectedInteraction?.translated?.expected_output?.data
              : selectedInteraction?.expected_output?.data
          }
        };

      default:
        return { created: '', data: {} };
    }
  };

  const { data: sectionData } = checkSectionData(section);

  const isFullPrompt = section === fullPromptKey;
  const isMultiSection = section === informationRetrievalKey || section === historyKey;
  const isTheOriginalInteraction = selectedInteraction?.user_interaction_id === sample?.user_interaction_id;
  const multiSectionContent =
    section === informationRetrievalKey
      ? isTranslation
        ? (selectedInteraction?.translated?.information_retrieval as TextEntrySchema[])
        : (selectedInteraction?.information_retrieval as TextEntrySchema[])
      : isTranslation
        ? (selectedInteraction?.translated?.history as TextEntrySchema[])
        : (selectedInteraction?.history as TextEntrySchema[]);

  useEffect(() => {
    setSection(sampleKey);
    setSelectedInteraction(sample);

    if (sample?.session_id && sample?.user_interaction_id) {
      setIsSessionInteractionsLoading(true);
      listInteractionsByFilter(versionId, {
        pagination_schema: {},
        filter_schema: { session_ids: [sample?.session_id] }
      })?.then(res => {
        setSessionInteractions(res);
        setIsSessionInteractionsLoading(false);
      });
    }
  }, [sample?.user_interaction_id, sample?.session_id]);

  const selectionsInnerCount = useMemo(() => {
    const historyKeyData = checkSectionData(historyKey).data || {};
    const fullPromptKeyData = checkSectionData(fullPromptKey).data || {};
    const informationRetrievalKeyData = checkSectionData(informationRetrievalKey).data || {};

    return (
      (steps?.[0] && steps?.length ? steps?.length : 0) +
      (fullPromptKeyData?.input || fullPromptKeyData?.output ? 1 : 0) +
      (informationRetrievalKeyData?.input || informationRetrievalKeyData?.output ? 1 : 0) +
      (historyKeyData?.input || historyKeyData?.output ? 1 : 0)
    );
  }, [steps?.[0], steps]);

  useEffect(() => {
    if (appId) {
      getSimilarApiV1ApplicationVersionsComparisonPut({
        env_type: type,
        application_id: appId,
        user_interaction_id: sample?.user_interaction_id
      }).then(res => {
        Array.isArray(res) && res.length >= 2 && setIsCompareDisabled(false);
      });
    }
  }, []);

  return (
    <SampleDetailsDialogBodyContainer empty={isSessionInteractionsLoading}>
      {isSessionInteractionsLoading ? (
        <StyledLoader />
      ) : (
        <>
          <SampleDetailsDialogSessionsSelectionContainer>
            {(Array?.isArray(sessionInteractions) ? sessionInteractions : [])?.map((sessionInteraction, index) => (
              <SampleDetailsDialogInteractionSelections
                key={index}
                steps={steps}
                section={section}
                sample={sessionInteraction}
                isTranslation={isTranslation}
                selectedInteraction={selectedInteraction}
                selectionsInnerCount={selectionsInnerCount}
                isCompareDisabled={isCompareDisabled || !isTheOriginalInteraction}
                setSection={setSection}
                setIsTranslation={setIsTranslation}
                checkSectionData={checkSectionData}
                setIsComparisonView={setIsComparisonView}
                setSelectedInteraction={setSelectedInteraction}
              />
            ))}
          </SampleDetailsDialogSessionsSelectionContainer>
          <SamplesDetailsBodyItem
            appId={appId}
            isPentest={isPentest}
            properties={properties}
            appVersionId={versionId}
            isFullPrompt={isFullPrompt}
            isTranslation={isTranslation}
            isMultiSection={isMultiSection}
            interaction={selectedInteraction}
            selectedColumns={selectedColumns}
            listOfProperties={listOfProperties}
            multiSectionContent={multiSectionContent}
            isAnnotationInteraction={isAnnotationInteraction}
            section={stepData || (sectionData as StepSchema)}
            isInfoRetrieval={section === informationRetrievalKey}
            llmReasons={selectedInteraction?.llm_properties_reasons}
            userInteractionId={selectedInteraction?.user_interaction_id}
            isTranslationExist={!!selectedInteraction?.translated?.input?.data}
            setSection={setSection}
            updateProperty={updateProperty}
            getPropertyInfo={getPropertyInfo}
            setIsTranslation={setIsTranslation}
            updateAnnotation={updateAnnotationAndSessionInteraction}
          />
        </>
      )}
    </SampleDetailsDialogBodyContainer>
  );
};
